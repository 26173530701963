import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fonts, colors } from '../../../globalStyles';
import P from '../../../components/_common/P';

const StyledAITypographyItem = styled.div`
	text-align: center;
	${P} {
		margin-top: 10px;
		font-family: ${fonts.robotoLightItalic};
		font-size: 16px;
		color: ${colors.greyItalicTitle};
		width: 100%;
	}
	.image-cover {
		height: 62px !important;
		width: 100%;
		img {
			display: block;
			margin: auto;
			max-height: 100%;
			max-width: 77px;
			vertical-align: center;
			width: auto;
		}
	}
`;

const AITypographyItem = ({ img, text }) => (
	<StyledAITypographyItem className="hidden-sm">
		<div className="image-cover">
			<img src={img.icon} alt={img.alt} />
		</div>
		<P className="">{text}</P>
	</StyledAITypographyItem>
);

AITypographyItem.propTypes = {
	img: PropTypes.any,
	text: PropTypes.string,
};
export default AITypographyItem;
