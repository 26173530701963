import React from 'react';
import styled from 'styled-components';

import Container from './../../../components/_common/Container';
import CenterButton from './../../../components/_common/CenterButton';
import ContentSection from './../../../components/ContentSection/';
import P from './../../../components/_common/P';

import { ForManyIndustriesData } from './ForManyIndustriesData';
import SolutionRow from './SolutionRow';
import { fonts, media, colors } from '../../../globalStyles';
import { Link } from 'gatsby';

const StyledSolutions = styled.div`
	.container {
		max-width: 1096px;
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		${P} {
			font-family: ${fonts.openSans};
			line-height: 30px;
			padding-bottom: 49px;
			a {
				color: ${colors.blackTitle};
			}
		}
	}
	.center-button-cover {
		margin-top: 112px;
		${media.maxSm`
				margin-top: 60px;
		`}
	}
	${media.maxSm`
		padding-bottom: 60px
	`}
`;

const Solutions = () => (
	<ContentSection
		fluid
		title="Diverse Artificial Intelligence & ML Solutions"
		subtitle="A smarter choice to providing better products and services"
		paddingBottom={140}
		seoTitle={'h2'}
		seoSubTitle={'h3'}
	>
		<StyledSolutions>
			<Container>
				<P>
					Change the way you think about apps, technology, and your business. In
					the late 20<sup>th</sup> century, computers transformed our lives,
					creating better and faster ways to gain information, communicate,
					entertain, and offer/acquire goods and services. Then came the mobile
					phone . . . and everything changed! More than a communication device,
					it completely revolutionized the world. Now, enter ML and AI. Even
					though they’ve been working behind the scenes since the dawn of
					computers, they have now taken center stage. Technologies have
					advanced to a point where machine learning, artificial intelligence,
					and deep learning are “must haves” if you own a business or plan to
					compete in today’s high-tech world. We use these intuitive and
					<Link to="/technologies"> emerging technologies</Link> for every
					industry.
				</P>

				{ForManyIndustriesData.map((solution, index) => (
					<div key={index} className="industry-item-cover">
						<SolutionRow
							index={solution.index}
							title={solution.title}
							text={solution.text}
						/>
					</div>
				))}
			</Container>
			<div className="center-button-cover">
				<CenterButton
					linkTo="/contact#contact-section"
					gaLabel={'technologies-machine-learning Start a project with us'}
				>
					Start A Project with us
				</CenterButton>
			</div>
		</StyledSolutions>
	</ContentSection>
);

export default Solutions;
