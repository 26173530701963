import React from 'react';
import { shape, string } from 'prop-types';
import PageLayout from '../layouts/PageLayout';
import SubpageTopCover from '../components/SubpageTopCover';
import DescriptionBlock from '../components/TechnologiesMachineLearning/PageDescription';
import Typography from '../components/TechnologiesMachineLearning/Typography';
import Solutions from '../components/TechnologiesMachineLearning/Solutions';
import MakeYourApp from '../components/TechnologiesMachineLearning/MakeYourApp';
import DeepLearning from '../components/TechnologiesMachineLearning/DeepLearning';
import CuttingEdge from '../components/TechnologiesMachineLearning/CuttingEdge';
import { useHeroPictureTechnologies } from '../hooks/use-hero-picture-technologies';
import { machineLearining } from '../seoConfig';

const TechnologiesMachineLearning = ({ location }) => {
	const img = useHeroPictureTechnologies();

	return (
		<PageLayout location={location} seoConfig={machineLearining}>
			<SubpageTopCover
				backgroundImg={img}
				altText={'pixelteh team with computers'}
			>
				Machine Learning & AI Development
			</SubpageTopCover>
			<DescriptionBlock />
			<Typography />
			<Solutions />
			<MakeYourApp />
			<DeepLearning />
			<CuttingEdge />
		</PageLayout>
	);
};
TechnologiesMachineLearning.propTypes = {
	location: shape({
		pathname: string.isRequired,
	}).isRequired,
};
export default TechnologiesMachineLearning;
