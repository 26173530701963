export const ForManyIndustriesData = [
	{
		index: '01',
		title: 'FINANCIAL SERVICES',
		text:
			'Banks, retirement fund managers, and others in financial industry ' +
			'use ML and AI technology for two primary purposes. First, they identify ' +
			'important insights in data to provide more profits for themselves and ' +
			'their clients. And second, these technologies are used to prevent fraud ' +
			'and theft. By quickly and thoroughly analyzing data, AI and ML identify ' +
			'investment opportunities. In addition, data analysis determines clients ' +
			'with high-risk profiles and uses cybersurveillance to recognize warning ' +
			'signs of fraud. For instance, PayPal now uses ML to fight money ' +
			'laundering and other schemes',
	},
	{
		index: '02',
		title: 'MEDICAL & HEALTH CARE',
		text:
			'In today’s hospitals and medical centers, AI helps to treat, ' +
			'predict, and even cure certain types of health conditions. Machine ' +
			'learning is one of the fastest-growing trends in the medical industry. ' +
			'For instance, scientists are using AI to predict how cancer progresses ' +
			'and evolves, which could help doctors prevent fatalities. Also, wearable ' +
			"devices and sensors use data to assess a patient's health in real time. " +
			'The technology assists medical specialists in analyzing data for trends ' +
			'or problems that lead to improved diagnoses and treatment.',
	},
	{
		index: '03',
		title: 'PERSONAL SECURITY',
		text:
			'As technology advances, so do the tactics of thieves. Therefore, ' +
			'a proactive and effective response to digital security is critical. ' +
			'Machine learning helps eliminate false alarms and spot things that human ' +
			'screeners might miss. Whether it’s in the airports, public venues, at ' +
			'work, around the home, or online, we are now using these technologies ' +
			'to accurately recognize problems and perpetrators – often before a crime ' +
			'is committed. Even now, AI is being used extensively to secure homes ' +
			'and businesses around the world.',
	},
	{
		index: '04',
		title: 'NATURAL LANGUAGE PROCESSING (NLP)',
		text:
			'Who are you talking to? It may be a chatbot – an artificial ' +
			'intelligence program. NLP is being used in all sorts of applications ' +
			'across nearly all disciplines. Machine learning algorithms take the ' +
			'place of customer service agents due to advanced algorithms. They more ' +
			'quickly route customers to the information they need. At the same time, ' +
			'in the legal, financial, and medical fields, it is used to translate ' +
			'obscure vargon into plain language. This allows various specialists to ' +
			'sort through large volumes of information in a short amount of time.',
	},
	{
		index: '05',
		title: 'MARKETING & SALES',
		text:
			'Have you ever been to a website, such as Facebook, and saw an ' +
			'add that seemed to be tailored to your interests? That’s machine ' +
			'learning! Now, advertisers are able to recommend items you might like ' +
			'based on previous purchases and searches. This is good for merchants and ' +
			'buyers because it streamlines the entire search-and-purchase process. ' +
			'As a business owner who sells goods and services, this is the future ' +
			'of retail. It allows you to capture data, analyze it, and use it to ' +
			'create a targeted ad and personalize the shopping experience.',
	},
	{
		index: '06',
		title: 'TRANSPORTATION',
		text:
			'There’s an easier and faster way to get from Point A to Point B. ' +
			'Google maps and other apps are able to instantly recognize traffic ' +
			'patterns and inform app users in the event of a problem. Also, AI ' +
			'automatically analyzes data to identify other key aspects that are ' +
			'important to the transportation industry. The data analysis and modeling ' +
			'aspects of machine learning are extremely helpful for those responsible ' +
			'for managing transportation companies, public transportation, and ' +
			'even airlines.',
	},
	{
		index: '07',
		title: 'SMART CARS & HOMES',
		text:
			'Would you like a car that drives itself or a home that provides ' +
			'complete automation? It’s coming! At the heart of it is machine learning ' +
			'and AI. Recently, IBM surveyed top auto executives. Approximately, 74% ' +
			'expected that we would start seeing smart cars on the road by 2025. ' +
			'Even now, our cars are getting smarter. In a matter of time, they’ll ' +
			'be driving themselves and even fixing their own problems. When it comes ' +
			'to smart homes, we already have homes that adjust lighting, sound, air, ' +
			'and other features based on who is at home.',
	},
];
