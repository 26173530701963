import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import H3 from '../../_common/H3';
import P from '../../_common/P';
import { colors, fonts, media } from '../../../globalStyles';

const StyledSolutionRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 60px;
	${media.maxSm`
		flex-wrap: wrap;
	`}
	.steps-content {
		width: 50%;
		${media.maxSm`
			margin-top: 60px
			width: 100%;
			text-align: center;
		`}
	}
	.step-index {
		font-size: 130px;
		line-height: 130px;
		color: ${colors.whiteGrey};
		width: 164px;
		margin: 0;
		${media.maxSm`
			text-align: center;
			width: 100%;
		`}
	}
	${H3} {
		font-size: 18px;
		color: ${colors.blackTitle};
		font-family: ${fonts.openSansSembiBold};
	}

	${P} {
		margin-top: 8px;
		font-size: 15px;
		color: #929398;
		font-family: ${fonts.robotoLightItalic}!important;
		line-height: 30px;
		padding-bottom: 0 !important;
	}
	.dot {
		margin: 0 96px 0 40px;
		flex: 1;
		position: relative;
		display: flex;
		align-self: center;
		justify-content: space-between;
		${media.tablet`
			margin-right: 40px;
			margin-left: 16px
		`}
		${media.maxSm`
				margin: -20px auto 0; 
				width: 60px;
				flex: none;
				transform: rotate(-90deg) translateX(-50%);
				
		`}
		&:after {
			height: 12px;
			width: 12px;
			background: ${colors.orange};
			content: '';
			display: block;
			border-radius: 12px;
			box-shadow: 0 0 9px rgba(0, 0, 0, 0.55);
		}
		&:before {
			height: 1px;
			width: 100%;
			content: '';
			display: block;
			background: ${colors.grey};
			align-self: center;
		}
	}
`;

const SolutionRow = ({ index, title, text }) => (
	<StyledSolutionRow>
		<p className="step-index">{index}</p>
		<div className="dot" />
		<div className="steps-content">
			<H3>{title}</H3>
			<P>{text}</P>
		</div>
	</StyledSolutionRow>
);

SolutionRow.propTypes = {
	index: PropTypes.number,
	title: PropTypes.string,
	text: PropTypes.string,
};
export default SolutionRow;
