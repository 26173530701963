import React from 'react';
import styled from 'styled-components';

import Container from '../../_common/Container';
import CenterButton from '../../_common/CenterButton';
import H5 from '../../../components/_common/H5';
import H3 from '../../../components/_common/H3';
import P from '../../../components/_common/P';
import border from '../../../assets/img/border-alt.png';
import Particles from '../../Particles';

import { fonts, colors, media } from '../../../globalStyles';
import head from '../../../assets/img/logo-head.png';

const StyledMakeYourApp = styled.section`
	min-height: 330px;
	position: relative;
	background-color: #365277;
	padding: 93px 0 90px;
	${media.maxSm`
		padding: 60px 0 50px;
	`}
	.container {
		position: relative;
		z-index: 2;
		text-align: center;
		${media.maxSm`
			max-width:100%;
		`}
	}

	.head-image {
		max-width: 128px;
		width: auto;
		${media.maxSm`
			max-width: 85px;
		`}
	}
	.center-button-cover {
		position: relative;
		z-index: 2;
	}
	${H5} {
		color: ${colors.white};
		font-family: ${fonts.robotoRegular};
		font-size: 26px;
		line-height: 32px;
		margin: 51px 0 34px 0;
		${media.maxSm`
			margin-top: 42px;
			margin-bottom: 0px
			font-size: 22px;
		`}
	}
	${H3} {
		color: #308fdf;
		font-size: 48px;
		font-family: ${fonts.robotoMedium};
		line-height: 54px;
		${media.maxSm`
			font-size: 26px;
		`}
	}
	${P} {
		color: ${colors.white};
		font-family: ${fonts.robotoLightItalic};
		font-size: 17px;
		line-height: 32px;
		padding: 39px 0 70px 0;
		${media.maxSm`
			padding: 26px 0 28px;
			font-size: 16px;
			line-height: 26px;
		`}
	}
	.content-section-top-border {
		background-image: url(${border});
		background-repeat: repeat-x;
		height: 32px;
		width: 100%;
		position: absolute;
		top: -32px;
		left: 0;
	}
`;
const MakeYourApp = () => (
	<StyledMakeYourApp>
		<div className="content-section-top-border" />
		<Container>
			<img
				src={head}
				className="head-image"
				alt="blue circle with head inside"
			/>
			<H5>AI & ML Programming</H5>
			<H3>Let’s Make Your Apps More Intelligent</H3>
			<P>
				Everyone wants to take advantage of the custom app craze. Android and
				iOS apps are here to stay. But just because you have an app doesn’t mean
				people will flock to your business. You need an app that runs on AI and
				ML. <b>PixelTeh</b> is an artificial intelligence and machine learning
				development company that designs, builds, and deploys apps that stand
				out from the rest. We know how to help you gain a competitive edge.
			</P>
		</Container>
		<div className="center-button-cover">
			<CenterButton
				linkTo="/contact#contact-section"
				gaLabel={'technologies-machine-learning Get In Touch'}
			>
				Get in touch
			</CenterButton>
		</div>
		<Particles />
	</StyledMakeYourApp>
);
export default MakeYourApp;
