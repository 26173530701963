import React from 'react';
import styled from 'styled-components';
import AITypographyItem from './TypographyItem';
import H3 from './../../../components/_common/H3';
import H5 from './../../../components/_common/H5';
import ml1 from './../../../assets/img/ml-item1.png';
import ml2 from './../../../assets/img/ml-item22.png';
import ml3 from './../../../assets/img/ml-item3.png';
import ml4 from './../../../assets/img/ml-item4.png';
import ml5 from './../../../assets/img/ml-item5.png';
import ml6 from './../../../assets/img/ml-item6.png';
import ml7 from './../../../assets/img/ml-item7.png';
import ml8 from './../../../assets/img/ml-8.png';
import head from './../../../assets/img/head.png';
import head2 from './../../../assets/img/head2.png';

import { fonts, media } from '../../../globalStyles';
import Container from '../../_common/Container';

const iconsTop = [
	{
		icon: ml1,
		alt: 'person holding scales',
	},
	{
		icon: ml2,
		alt: 'head with brain image',
	},
	{
		icon: ml3,
		alt: 'circle with math symbols',
	},
];
const iconsBottom = [
	{
		icon: ml4,
		alt: 'flower with cpu',
	},
	{
		icon: ml5,
		alt: 'two heads and a lightbulb',
	},
	{
		icon: ml6,
		alt: '3d square with circle corners',
	},
	{
		icon: ml7,
		alt: 'paper coming out of cloud',
	},
	{
		icon: ml8,
		alt: 'eye in a cloud',
	},
];
const iconsMoblie = [
	{
		icon: ml4,
		alt: 'flower with cpu',
	},
	{
		icon: ml5,
		alt: 'two heads and a lightbulb',
	},
	{
		icon: ml6,
		alt: '3d square with circle corners',
	},
];
const iconsMoblieText = ['AI', 'NLP', 'ML'];
const iconsTopTexts = ['Decision Making', 'Human Memory', 'Problem Solving'];
const iconsBottomTexts = [
	'Artificial Intelligence',
	'Natural Language Processing',
	'Machine Learning',
	'Cloud Data',
	'Computer Vision',
];

const StyledMLTypography = styled.div`
	padding-bottom: 120px;
	${media.maxSm`
		padding-bottom: 50px;
	`}
	.container {
		max-width: 1110px;
	}
	.main-block-cover {
		margin: auto;
	}
	.typography-icons-row {
		max-width: 704px;
		margin: auto;
		display: flex;
		justify-content: space-between;
		${media.maxSm`
			display:none
		`}
	}
	.typography-item-cover {
		text-align: center;
	}
	.typography-icons-row__top {
		.typography-item-cover {
			:nth-child(2n + 1) {
				margin-top: 106px;
			}
		}
	}
	.typography-head-image {
		width: 50%;
		max-width: 470px;
		height: auto;
		display: block;
		margin: auto;
	}
	${H3} {
		text-align: center;
		font-family: ${fonts.robotoBold};
		padding: 30px 0 22px;
	}
	.typography-icons-row__bottom {
		max-width: 850px;
		.typography-item-cover {
			width: 18%;
			min-height: 90px;
			div {
				height: 100%;
				display: flex;
				flex-wrap: wrap;
			}
		}
	}
	.typography-head-image__top {
		${media.minSm`
			margin-top: -52px;
		`}
	}
	.typography-head-image__second {
		${media.maxSm`
			display: none;
		`}
	}
	.icons-mobile-row {
		display: none;
		margin: 40px auto 0;
		text-align: center;
		max-width: 360px;
		${media.maxSm`
			display: flex;
			
		`}
		.icons-mobile-image-cover {
			width: 88px;
			height: 88px;
			background-color: #fbfbfb;
			border-radius: 19px;
			box-shadow: 0.5px 0.9px 8px rgba(0, 0, 0, 0.3);
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				max-height: 50px;
				max-width: 50px;
				width: auto;
				height: auto;
				display: inline-block;
				vertical-align: center;
			}
		}
		${H5} {
			margin-top: 21px;
			font-size: 15px;
		}
	}
`;
const MLTypographyQuestion = () => (
	<StyledMLTypography>
		<Container>
			<div className="main-block-cover">
				<div className="typography-icons-row typography-icons-row__top">
					{iconsTop.map((img, index) => (
						<div key={index} className="typography-item-cover">
							<AITypographyItem img={img} text={iconsTopTexts[index]} />
						</div>
					))}
				</div>
				<img
					className="typography-head-image typography-head-image__top"
					src={head}
					alt="orange ai brain"
				/>
				<H3>Building Intelligent Apps</H3>
				<img
					className="typography-head-image typography-head-image__second"
					src={head2}
					alt="connect the dots web"
				/>
			</div>

			<div className="typography-icons-row typography-icons-row__bottom">
				{iconsBottom.map((img, index) => (
					<div key={index} className="typography-item-cover">
						<AITypographyItem img={img} text={iconsBottomTexts[index]} />
					</div>
				))}
			</div>
			<div className="typography-icons-row icons-mobile-row">
				{iconsMoblie.map((img, index) => (
					<div key={index} className="icons-mobile-item">
						<div className="icons-mobile-image-cover">
							<img src={img.icon} alt={img.alt} />
						</div>
						<H5>{iconsMoblieText[index]}</H5>
					</div>
				))}
			</div>
		</Container>
	</StyledMLTypography>
);

export default MLTypographyQuestion;
