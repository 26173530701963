import React from 'react';
import P from '../_common/P';
import PageDescription from '../PageDescription';

const DescriptionBlock = () => (
	<PageDescription
		title="Machine Learning for a More Meaningful Experience"
		subtitle="Teaching programs how to think for themselves"
		bgColor="white"
	>
		<P>
			The Chinese proverb goes, “Give a man a fish, and you feed him for a day.
			Teach a man to fish, and you feed him for a lifetime.” Believe it or not,
			the same thing is true for computers. But we’re not talking about fish,
			but code. With traditional programming, we write lines of code, and the
			website, app, or computer-operated system follows the instructions
			perfectly. But with machine learning and artificial intelligence
			programming, it essentially learns on its own – even developing its own
			code. <b>PixelTeh</b> uses ML and AI programming to make computers
			smarter, helping people in a wide variety of ways. The uses for advanced
			technology span the gamut – from medical and healthcare to security,
			manufacturing, gaming, and much more!
		</P>
	</PageDescription>
);

export default DescriptionBlock;
