import React from 'react';
import styled from 'styled-components';

import Container from './../../../components/_common/Container';
import CenterButton from './../../../components/_common/CenterButton';
import P from './../../../components/_common/P';
import H1 from './../../../components/_common/H1';
import H2 from './../../../components/_common/H2';
import H3 from './../../../components/_common/H3';
import bgStripes from './../../../assets/img/border-vertical.png';
import AnimatedOnScroll from './../../../components/_common/AnimatedOnScroll';
import { ColumnsData } from './ColumnsData';

import { fonts, colors, media } from '../../../globalStyles';

const StyledCuttingEdge = styled.div`
	.container {
		max-width: 1254px;
		margin: 133px auto 88px;
		${P} {
			margin-top: 63px;
			font-family: ${fonts.openSans};
			line-height: 30px;
		}
	}
	${H1} {
		color: ${colors.blackTitle};
		font-family: ${fonts.robotoBold};
		font-size: 28px;
		line-height: 32px;
		text-transform: uppercase;
		padding-bottom: 51px;
		text-align: center;
		${media.maxSm`
			font-size: 18px;
			line-height: 30px;
			padding-bottom: 30px;
		`}
	}
	${H2} {
		color: ${colors.greyItalicTitle};
		font-family: ${fonts.robotoLightItalic};
		font-size: 17px;
		line-height: 30px;
		text-align: center;
		${media.maxSm`
			font-size: 15px;
			line-height: 28px;
		`}
	}
	${H3} {
		width: 100%;
		color: #2d2d2d;
		font-family: ${fonts.robotoBold};
		font-size: 18px;
		text-transform: uppercase;
		margin: 10px 0 76px 0;
		position: relative;
		${media.maxSm`
			margin-bottom: 40px;
		`}
		&:before {
			display: block;
			content: '';
			position: absolute;
			top: -16px;
			left: 50%;
			transform: translateX(-50%);
			width: 51px;
			height: 2px;
			background-color: ${colors.orange};
		}
	}

	.center-button-cover {
		margin-top: 80px;
	}

	.columns-cover {
		margin-top: 127px;
		display: flex;
		justify-content: space-between;
		${media.maxSm`
			margin-top: 20px;
			flex-wrap: wrap;
		`}
		.column-item {
			width: 30%;
			position: relative;
			text-align: center;
			flex-direction: column;
			justify-content: space-between;
			padding-bottom: 40px;
			${media.maxSm`
				width: 100%;
				margin-top: 60px;
				padding-bottom: 0;
			`}
			&:nth-child(2) {
				width: 33%;
				${media.maxSm`
					padding-top: 40px;
					padding-bottom: 40px;
					width: 100%
				`}	
				&:before,
				&:after {
					position: absolute;
					display: block;
					content: '';
					width: 20px;
					height: 100%;
					top: 0;
					left: -40px;
					background-image: url(${bgStripes});
					background-repeat: repeat;
					${media.laptopS`
						left: -20px;
					`}
					${media.tablet`
						left: -18px;
					`}
					${media.maxSm`
						width: 100%;
						height: 20px;
						top: -20px;
						left: 0;
					`}
				}
				&:after {
					left: auto;
					right: -40px;
					${media.laptopS`
						right: -20px;
					`}
					${media.tablet`
						right: -18px;
					`}
					${media.maxSm`
						top: auto;
						bottom: -20px;
					`}
				}
			}
		}
		.column-block-item {
			margin: 25px auto 0;
			width: 210px;
			height: 58px;
			background-color: #308fdf;
			box-shadow: 0.5px 0.9px 8px rgba(0, 0, 0, 0.3);
			color: ${colors.white};
			font-family: ${fonts.robotoLightItalic};
			font-size: 15px;
			line-height: 18px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-left: 10px;
			padding-right: 10px;
		}
	}
`;

const CuttingEdge = () => (
	<StyledCuttingEdge>
		<Container>
			<AnimatedOnScroll>
				<H1 as={'h2'}>
					PIXELTEH ON THE CUTTING EDGE OF AI, ML, AND DEEP LEARNING
				</H1>
			</AnimatedOnScroll>
			<AnimatedOnScroll>
				<H2 as={'p'}>
					What do you want in your technology partner? You need a programming
					company that has one foot in reality and the other in future
					possibilities. Most importantly, you need a team with experience and
					the ability to fulfill everything according to your expectations. For
					more than a decade, <b>PixelTeh</b> has provided app development,
					programming, and technological solutions for clients throughout the
					world. As innovative programmers, we stay up to date with the latest
					advances in artificial intelligence. So, when you choose us to develop
					your program, you know you’re getting the best of the best. Some of
					the projects we’ve completed include:
				</H2>
			</AnimatedOnScroll>
			<div className="columns-cover">
				{ColumnsData.map(columnItemData => (
					<div key={columnItemData.title} className="column-item">
						<H3 as={'p'}>{columnItemData.title}</H3>

						{columnItemData.content.map(contentItem => (
							<div key={contentItem} className="column-block-item">
								{contentItem}
							</div>
						))}
					</div>
				))}
			</div>
			<P>
				Unleash the power of AI by depending on the programmers and developers
				at <b>PixelTeh</b>. We help you acquire the right data for your needs
				and make the best use of it. From using AI as customer service chatbots
				to tailoring ads for your target market to predicting various outcomes
				based on business data, there are so many ways AI can improve the
				products and services you provide. But also, your profits. Be a smart
				business owner with artificial intelligence. Schedule a consultation
				with us today for more information.
			</P>
			<div className="center-button-cover">
				<CenterButton
					linkTo="/contact#contact-section"
					gaLabel={'technologies-machine-learning Start A Project with us'}
				>
					Start A Project with us
				</CenterButton>
			</div>
		</Container>
	</StyledCuttingEdge>
);

export default CuttingEdge;
