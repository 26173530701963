import React from 'react';
import styled from 'styled-components';

import P from './../../../components/_common/P';
import H5 from './../../../components/_common/H5';

import { fonts, colors, media } from '../../../globalStyles';

const StyledGraphicComponent = styled.div`
	margin-top: 60px;
	width: 100%;
	.content-cover {
		width: 100%;
		position: relative;
	}
	.explanation-cover {
		position: absolute;
		width: 100%;
		height: 100%;
		padding-top: 46px;
		top: 0;
		left: 0;
		.explanation-item {
			display: flex;
			justify-content: space-between;
			width: 56%;
			align-items: center;
			${P} {
				max-width: 250px;
				margin-right: 24px;
				color: ${colors.greyItalicTitle};
				font-family: ${fonts.robotoLightItalic};
				font-size: 15px;
				line-height: 24px;
				text-align: center;
				${media.laptopS`
					margin-right: 0!important;
					margin-left: 0!important;
					margin-top:	24px;	
				`}
			}
			.box {
				width: 10px;
				height: 10px;
				border: 1px solid #c4c4c4;
			}
			.line {
				height: 1px;
				background: #d3d3d3;
				width: 100%;
				margin-right: 70px;
				position: relative;
				display: block;
				&:after {
					width: 50px;
					content: '';
					display: inline-block;
					height: 1px;
					background: #d3d3d3;
					width: 50px;
					position: absolute;
					right: -43px;
					transform: rotate(45deg);
					top: 17px;
				}
				&:before {
					position: absolute;
					display: block;
					content: '';
					height: 11px;
					width: 11px;
					background: ${colors.orange};
					content: '';
					border-radius: 12px;
					box-shadow: 0 0 9px rgba(0, 0, 0, 0.55);
					right: -41px;
					bottom: -41px;
					z-index: 2;
				}
			}
			${media.laptopS`
				flex-direction: column-reverse!important;
				margin 0 auto!important;
				max-width: none!important;
				width: 100%!important;
				padding: 0 20px 20px;
				position: relative;
				z-index: 5;
				${H5}{
					margin-top: 0px;
					position: relative;
					&:before {
						position: absolute;
						display: block;
						content: '';
						height: 11px;
						width: 11px;
						background: ${colors.orange};
						content: '';
						border-radius: 12px;
						box-shadow: 0 0 9px rgba(0, 0, 0, 0.55);
						left: -19px;
						top: 4px;
						z-index: 2;
					}	
				}
			`}
		}
		.explanation-item__second {
			margin-top: 40px;
			margin-left: auto;
			flex-direction: row-reverse;
			width: 54%;
			.direction-element {
				transform: scaleX(-1);
			}
			${P} {
				max-width: 250px;
				margin-right: 0;
				margin-left: 24px;
			}
			${media.maxXl`
				width: 56%
			`}
			${media.laptop`
				margin-top: 90px;
			`}
			${media.laptopS`
				padding-top: 20px;
			`}
		}
		.explanation-item__third {
			margin-top: 80px;
			margin-left: auto;
			flex-direction: row-reverse;
			width: 54%;
			padding-right: 40px;
			.direction-element {
				transform: scaleX(-1);
			}
			.line {
				margin-right: 16px;
				&:after {
					display: none;
				}
				&:before {
					top: -5px;
					right: 0;
				}
			}
			${H5} {
				margin-top: 0;
			}
			${P} {
				max-width: 250px;
				margin-right: 0;
				margin-left: 24px;
			}
			${media.maxXl`
				margin-top: 120px;
				width: 55%;
			`}
			${media.laptop`
				margin-top: 80px;
			`}
			${media.laptopS`
				padding-top: 20px;
				padding-right:20px;	
				padding-bottom: 40px;
			`}
		}
		
		${media.laptopS`
			position: relative;
    	border: 2px dotted #ededed;
			background-color: rgba(234, 234, 234, 0.3);
			padding: 20px;
			&:after{
				position: absolute;
				height: 66%;
				width: calc(100% - 40px);
				left: 20px;
				bottom: 20px;
				display: block;
				content: '';	
				background-color: rgba(234, 234, 234, 0.6);
			}
			&:before{
				position: absolute;
				height: calc(33% - 10px);
				width: calc(100% - 80px);
				left: 40px;
				background: transparent;
				bottom: 40px;
				display: block;
				content: '';	
				background-size: 8px 8px;
				background-image: repeating-linear-gradient(45deg, #d9d9d9, #d9d9d9 10%, transparent 20%, transparent 50%, #d9d9d9 50%);			
			}
			.direction-element{
				display: none;
			}
		`}
		${media.phone`
			&:before{
				height: 33%
			}
			&:after{
				
			}
		`}
		
	}
	.circles-cover {
		margin: 0 auto 0;
		width: 50vw;
		height: 50vw;
		max-width: 556px;
		max-height: 556px;
		border-radius: 557px;
		background-color: rgba(234, 234, 234, 0.3);
		border-radius: 50%;
		border: 2px dotted #ededed;
		box-shadow: inset 2px 0px 0px #fff, inset -2px 0px 0px #fff,
			inset 0px 2px 0px #fff, inset 0px -2px 0px #fff;
		display: flex;
		align-items: flex-end;
		justify-items: center;
		padding-bottom: 36px;
		${media.laptopS`
			display: none;
		`}
	}

	.second-circle {
		background-color: rgba(234, 234, 234, 0.6);
		box-shadow: none;
		width: 27vw;
		height: 27vw;
		max-width: 383px;
		max-height: 383px;
		padding-bottom: 20px;
	}

	.third-circle {
		box-shadow: none;
		max-width: 213px;
		max-height: 213px;
		width: 18vw;
		height: 18vw;
		background: repeating-linear-gradient(
			45deg,
			#d9d9d9,
			#d9d9d9 10%,
			transparent 20%,
			transparent 50%,
			#d9d9d9 50%
		);
		background-size: 8px 8px;
	}

	${H5} {
		margin: 40px 0 0;
		color: #2d2d2d;
		font-family: ${fonts.robotoMedium};
		font-size: 17px;
		white-space: nowrap;
	}

	.direction-element {
		display: flex;
		justify-content: space-between;
		width: 60%;
		align-items: center;
	}
`;

const GraphicComponent = () => (
	<StyledGraphicComponent>
		<div className="content-cover">
			<div className="circles-cover">
				<div className="circles-cover second-circle">
					<div className="circles-cover third-circle" />
				</div>
			</div>
			<div className="explanation-cover">
				<div className="explanation-item">
					<P>Creation of intelligent machines that act & work like humans</P>
					<div className="direction-element">
						<div className="box" />
						<div className="line" />
						<div className="dot" />
					</div>
					<H5>Artificial Intelligence</H5>
				</div>
				<div className="explanation-item explanation-item__second">
					<P>Programming computers to learn without writing additional code</P>
					<div className="direction-element">
						<div className="box" />
						<div className="line" />
						<div className="dot" />
					</div>
					<H5>Machine Learning</H5>
				</div>

				<div className="explanation-item explanation-item__third">
					<P>Training machines and programs to operate like the human brain</P>
					<div className="direction-element">
						<div className="box" />
						<div className="line" />
						<div className="dot" />
					</div>
					<H5>Deep Learning</H5>
				</div>
			</div>
		</div>
	</StyledGraphicComponent>
);

export default GraphicComponent;
