export const ColumnsData = [
	{
		title: 'IMAGE & SOUND RECOGNITION',
		content: [
			'License Plate Recognition',
			'Barking Dog Detection',
			'Face Detection for a Mobile App',
		],
	},
	{
		title: 'NLP (NATURAL LANGUAGE PROCESSING)',
		content: [
			'eCommerce Chatbots',
			'Dating Website Chatbots',
			'Customer Service NLP',
		],
	},
	{
		title: 'AI DATA ANALYSIS & PREDICTION',
		content: [
			'Stock Trading Predictions',
			'Investment Fund Analysis',
			'Financial Data Forecasting',
		],
	},
];
