import React from 'react';
import styled from 'styled-components';

import Container from './../../../components/_common/Container';
import ContentSection from './../../../components/ContentSection/';
import P from './../../../components/_common/P';
import GraphicComponent from './GraphicComponent';

import { fonts, media } from '../../../globalStyles';

const StyledDeepLearning = styled.div`
	.container {
		max-width: 1350px;
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		${P} {
			font-family: ${fonts.openSans};
			line-height: 30px;
		}
	}
	.center-button-cover {
		margin-top: 60px;
	}
	section {
		${media.tablet`
			padding-bottom: 0;
		`}
	}
`;

const DeepLearning = () => (
	<StyledDeepLearning>
		<ContentSection
			fluid
			title="Deep Learning"
			subtitle="Solving problems from end to end"
			seoTitle={'h2'}
			seoSubTitle={'h3'}
		>
			<Container>
				<P>
					Deep Learning is a comprehensive approach to AI and ML. In fact, it
					goes much “deeper” than those two technologies by breaking down tasks
					in ways that the human brain might. It makes all kinds of machine
					assists seem possible. Deep learning is at the very heart of
					driverless cars, preventive healthcare, and even better gaming. All
					those sci-fi movies and books? Well, many might actually become a
					reality with deep learning.
				</P>
				<br />
				<br />
				<br />
				<P>
					A DL model continually analyzes data with a logic structure that is
					similar to how a human would draw conclusions. It uses a layered
					structure of algorithms called an artificial neural network (ANN). The
					design of an ANN is inspired by the biological neural network of the
					human brain. This makes for machine intelligence that’s far more
					capable than that of standard machine learning models. In fact, it can
					learn and make intelligent decisions on its own. Think Terminator –
					but without all the violence.
				</P>

				<GraphicComponent />
			</Container>
		</ContentSection>
	</StyledDeepLearning>
);

export default DeepLearning;
